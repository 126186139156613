import(/* webpackMode: "eager", webpackExports: ["ClientProviders"] */ "/vercel/path0/apps/url-proxy/src/app/[lang]/client-providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/url-proxy/src/styles/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/tailwind-config/global.css");
