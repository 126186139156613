"use client";

import React, { Suspense, useEffect } from "react";
import { Toaster } from "@irbano/react-components/dist/components/ui/toast";
import { PosthogProvider } from "../posthog/posthog-provider";
import PostHogPageView from "../posthog/posthog-page-view";
import i18next from "i18next";
import { Locale } from "i18n-config";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";

export type ClientProvidersProps = {
  children: React.ReactNode;
  translations: any;
  lang: Locale;
};

export const ClientProviders = ({
  children,
  translations,
  lang,
}: ClientProvidersProps) => {
  useEffect(() => {
    i18next.init({
      lng: lang,
      resources: {
        [lang]: translations,
      },
    });

    z.setErrorMap(zodI18nMap);
  }, [lang, translations]);
  return (
    <>
      <PosthogProvider>
        <Suspense>
          <PostHogPageView />
        </Suspense>

        {children}
      </PosthogProvider>

      <Toaster />
    </>
  );
};
